<template>
  <component
    :is="theme"
    v-if="theme"
    :key="theme"
  />
</template>

<script>
  export default {
    name: 'BrokerTheme',
    components: {
      FreedomTheme: () => import(/* webpackChunkName: 'themes/broker/freedom' */
        './FreedomTheme'),
      CentumTheme: () => import(/* webpackChunkName: 'themes/broker/centum' */
        './CentumTheme')
    },
    computed: {
      theme () {
        let theme
        switch (this.MARKET) {
          case 'se':
            theme = 'freedom-theme'
            break
          case 'no':
            theme = 'centum-theme'
            break
          case 'fi':
            theme = 'freedom-theme'
            break
        }

        return theme
      }
    }
  }
</script>
