var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.theme
    ? _c(_vm.theme, { key: _vm.theme, tag: "component" })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }